import { render, staticRenderFns } from "./iconMastercard.vue?vue&type=template&id=bab684c4&scoped=true&"
import script from "./iconMastercard.vue?vue&type=script&lang=js&"
export * from "./iconMastercard.vue?vue&type=script&lang=js&"
import style0 from "./iconMastercard.vue?vue&type=style&index=0&id=bab684c4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bab684c4",
  null
  
)

export default component.exports