import { render, staticRenderFns } from "./iconTroy.vue?vue&type=template&id=412bbc26&scoped=true&"
import script from "./iconTroy.vue?vue&type=script&lang=js&"
export * from "./iconTroy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "412bbc26",
  null
  
)

export default component.exports